<template>
  <div>
    <h4 class="text-center mt-5 mb-3">
      <span class="text-success text-lg">{{
        footballLiveMatch.home_team_name
      }}</span>
      <small class="text-sm text-danger"> VS </small>
      <span class="text-success text-lg">{{
        footballLiveMatch.away_team_name
      }}</span>
    </h4>
    <div class="p-1">
      <div
        class="mx-auto p-0 d-flex main-image-wrapper mt-2 pt-1"
        v-html="footballLink"
      ></div>
    </div>
    <div class="p-3 d-flex justify-content-center flex-wrap">
      <button
        class="col-3 btn btn-danger text-lg text-uppercase"
        style="margin-top: 1rem !important"
        v-for="(link, index) in footballLiveMatch.servers"
        :key="index"
        @click="changeLink(link.link)"
      >
        {{ link.name }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      footballLiveMatch: {},
      footballLink: null,
    };
  },
  methods: {
    changeLink(link) {
      this.footballLink = `<iframe src='https://i.fdcdn.xyz/v11/?id=KEsECpu&theme_id=vebotv&t=1&n=SD1&c=24&link=${link}' width='100%' height='300px' allowtransparency='yes' allow='autoplay' frameborder='0' marginheight='0' marginwidth='0' allowfullscreen='' scrolling='auto'></iframe>`;
    },
    processPause(progress) {
      console.log(progress);
    },
    async fetchMatches() {
      try {
        const res = await axios.get('football-live-matches');
        this.footballLiveMatch = res.data.data[this.$route.query.index];
        this.footballLink = `<iframe src='https://i.fdcdn.xyz/v11/?id=KEsECpu&theme_id=vebotv&t=1&n=SD1&c=24&link=${this.footballLiveMatch.servers[0]?.link}' width='100%' height='300px' allowtransparency='yes' allow='autoplay' frameborder='0' marginheight='0' marginwidth='0' allowfullscreen='' scrolling='auto'></iframe>`;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchMatches();
  },
};
</script>
