<template>
    <div class="container-fluid d-flex flex-wrap mt-5 main-wrapper">
        <!-- <div class="col-lg-3 col-12 pl-0 side-nav-bar-custom">
            <SideNav />
        </div> -->
        <div class="col-lg-9 col-12 p-0">
            <!-- Card header -->
            <div
                class="card-header border-0 d-flex flex-column justify-content-between flex-wrap"
            >
                <div class="d-flex justify-content-between">
                    <h3>
                        {{
                            $store.state.language === "en"
                                ? "Betslip History"
                                : "ဘောက်ချာများ"
                        }}
                    </h3>
                    <div>
                        <!-- <button
                            type="button"
                            class="btn btn-outline-success"
                            :class="type === 'twod' ? 'active' : ''"
                            @click="changeType('twod'), fetchHistory()"
                        >
                            {{
                                $store.state.language === "en"
                                    ? "2D"
                                    : "နှစ်လုံး"
                            }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-primary"
                            :class="type === 'threed' ? 'active' : ''"
                            @click="changeType('threed'), fetchHistory()"
                        >
                            {{
                                $store.state.language === "en"
                                    ? "3D"
                                    : "သုံးလုံး"
                            }}
                        </button> -->
                        <button
                            type="button"
                            class="btn btn-outline-primary"
                            :class="type === 'maung' ? 'active' : ''"
                            @click="changeType('maung'), fetchHistory()"
                        >
                            {{
                                $store.state.language === "en"
                                    ? "Maung"
                                    : "မောင်း"
                            }}
                        </button>
                        <button
                            type="button"
                            class="btn btn-outline-primary"
                            :class="type === 'body' ? 'active' : ''"
                            @click="changeType('body'), fetchHistory()"
                        >
                            {{
                                $store.state.language === "en"
                                    ? "Body"
                                    : "ဘော်ဒီ"
                            }}
                        </button>
                    </div>
                </div>

                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex col-lg-6 col-12 justify-content-end p-0">
                        <div
                            class="col-lg-5 col-5 px-2 select-containers pt-1"
                            v-if="type !== 'threed'"
                        >
                            <!-- Date : -->
                            <v-select
                                v-model="params.date"
                                :reduce="(draw) => draw.data"
                                label="label"
                                :options="dateOptions"
                            ></v-select>
                        </div>
                        <!-- <div
                            class="col-lg-5 col-7 px-1 select-containers"
                            v-if="type === 'threed'"
                        >
                            Draw :
                            <v-select
                                v-model="params.date"
                                :reduce="(draw) => draw.data"
                                label="label"
                                :options="dateOptionsThreed"
                            ></v-select>
                        </div> -->

                        <div>
                            <button
                                type="button"
                                class="btn btn-primary mt-4 ml-1"
                                style="flex-shrink: 0"
                                @click="fetchHistory"
                            >
                                {{
                                    $store.state.language === "en"
                                        ? "Search"
                                        : "ရှာမည်"
                                }}
                            </button>
                        </div>
                    </div>
                    <div
                        class="d-flex justify-content-end"
                        v-if="type === 'twod' || type === 'threed'"
                    >
                        <b-form-checkbox
                            size="lg"
                            v-model="isBingo"
                            class="my-auto"
                            v-on:change="fetchHistory()"
                        ></b-form-checkbox>
                        <b class="my-auto">{{
                            $store.state.language === "en"
                                ? "Bingo ?"
                                : "ပေါက်ဂဏန်းပါ"
                        }}</b>
                    </div>
                </div>
            </div>
            <div class="table table-responsive betslip-table pb-3">
                <div class="d-flex" v-if="isSearch">
                    <div class="mx-auto my-5">
                        <Loading
                            color="#ff0000"
                            size="24"
                            class="px-4 py-0"
                        />
                        <span>
                            Please Wait!
                        </span>
                    </div>
                </div>

                <table class="table align-items-center table-flush" v-if="!isSearch">
                    <thead class="thead-light">
                        <tr>
                            <th>
                                <div class="d-flex">
                                    <div class="mx-auto">
                                        {{
                                            $store.state.language === "en"
                                                ? "Voucher Id"
                                                : "ဘောက်ချာနံပါတ်"
                                        }}
                                    </div>
                                </div>
                            </th>

                            <th>
                                <div class="d-flex">
                                    <div class="mx-auto">
                                        {{
                                            $store.state.language === "en"
                                                ? "Remark"
                                                : "မှတ်ချက်"
                                        }}
                                    </div>
                                </div>
                            </th>
                            <th v-if="isBingo">
                                <div class="d-flex">
                                    <div class="mx-auto">
                                        {{
                                            $store.state.language === "en"
                                                ? "Bingo Amount"
                                                : "ပေါက်ဂဏန်း ပမာဏ"
                                        }}
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex">
                                    <div class="mx-auto">
                                        {{
                                            $store.state.language === "en"
                                                ? "Total Amount"
                                                : "စုစုပေါင်း"
                                        }}
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div class="d-flex">
                                    <div class="mx-auto">
                                        {{
                                            $store.state.language === "en"
                                                ? "Bet Date"
                                                : "ထိုးသည့်နေ့စွဲ"
                                        }}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="list">
                        <tr
                            v-for="(history, index) in betHistory"
                            :key="index"
                            @click="getSlipdata(history)"
                            class="betsliptable-row"
                        >
                            <th scope="row">
                                <div class="media d-flex">
                                    <span class="mx-auto mb-0 text-md">{{
                                        history.voucher_id
                                    }}</span>
                                </div>
                            </th>
                            <th scope="row">
                                <div class="media d-flex">
                                    <span
                                        class="mx-auto mb-0 badge badge-danger"
                                        v-if="!history.isBet"
                                    >
                                        Incomplete
                                    </span>
                                    <span
                                        class="mx-auto mb-0 text-md text-danger"
                                        v-if="
                                            history.isBet &&
                                            (history.bingo_amount > 0 ||
                                                history.is_bingo)
                                        "
                                    >
                                        <i
                                            class="fas fa-check text-success pr-0"
                                        ></i>
                                    </span>
                                    <span
                                        class="mx-auto mb-0 text-md text-danger"
                                        v-if="
                                            history.isBet && !history.is_bingo
                                        "
                                    >
                                        <i class="fas fa-times text-danger"
                                            ></i>
                                    </span>
                                </div>
                            </th>
                            <th scope="row" v-if="isBingo">
                                <div class="media d-flex">
                                    <span class="mx-auto mb-0 text-md">{{
                                        history.bingo_amount
                                    }}</span>
                                </div>
                            </th>
                            <th scope="row">
                                <div class="media d-flex">
                                    <span class="mx-auto mb-0 text-md">{{
                                        history.total_amount
                                    }}</span>
                                </div>
                            </th>
                            <th scope="row">
                                <div class="media d-flex">
                                    <span class="mx-auto mb-0 text-md">{{
                                        betDate(history.created_at)
                                    }}</span>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th scope="row"></th>
                            <th scope="row"></th>
                            <th scope="row" v-if="isBingo">
                                <div class="media d-flex">
                                    <span class="mx-auto mb-0 text-md"
                                        >Total -
                                        <b class="text-danger">{{
                                            Number(
                                                totalBingoAmount()
                                            ).toLocaleString()
                                        }}</b></span
                                    >
                                </div>
                            </th>
                            <th scope="row">
                                <div class="media d-flex">
                                    <span class="mx-auto mb-0 text-md"
                                        >Total -
                                        <b class="text-success">{{
                                            Number(
                                                totalVoucherAmount()
                                            ).toLocaleString()
                                        }}</b></span
                                    >
                                </div>
                            </th>
                            <th></th>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- === Voucher History === -->
            <div class="col-4">
                <div
                    class="modal fade"
                    id="voucher-history"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="modal-form"
                    aria-hidden="true"
                >
                    <div
                        class="modal-dialog modal- modal-dialog-centered"
                        role="document"
                    >
                        <div class="modal-content">
                            <div
                                class="text-center modal-cross"
                                @click="closeModal"
                            >
                                X
                            </div>
                            <div
                                class="modal-body p-0"
                                v-if="type === 'maung' || type === 'body'"
                            >
                                <FBVoucher
                                    :type="type"
                                    :datas="datas"
                                    :slipId="slipId"
                                    :currentTime="bet_date"
                                    :isHistory="true"
                                    :is_result="is_result"
                                    :bingo_amount="bingo_amount"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import SideNav from './components/SideNav.vue';
import { DateTime } from 'luxon';
import { Loading } from 'vant';
import FBVoucher from './components/FBVoucher.vue';

export default {
  components: {
    // SideNav,
    Loading,
    FBVoucher,
  },
  data() {
    return {
      isSearch: false,
      isBingo: false,
      type: 'maung',
      remark: '',
      name: '',
      searchKeyword: '',
      params: {
        draw: '',
        date: '',
      },
      dateOptionsThreed: [
        {
          label: null,
          data: null,
        },
        {
          label: null,
          data: null,
        },
      ],
      dateOptions: [],
      betHistory: {},
      datas: [],
      slipId: null,
      bet_date: null,
      isTwod: true,
      bingo_amount: null,
      is_result: false,
    };
  },
  methods: {
    totalVoucherAmount() {
      let total = 0;
      for (let i = 0; i < this.betHistory.length; i += 1) {
        total += parseInt(this.betHistory[i].total_amount, 10);
      }
      return total;
    },
    totalBingoAmount() {
      let total = 0;
      for (let i = 0; i < this.betHistory.length; i += 1) {
        total += parseInt(this.betHistory[i].bingo_amount, 10);
      }
      return total;
    },
    setLastHalfMonth() {
      const d = new Date();
      const f = new Date();
      const s = new Date();

      if (d.getDate() <= 16 && d.getDate() !== 1) {
        f.setDate(16);
        s.setDate(1);
        this.dateOptionsThreed[0].label = moment(f).format('ll');
        this.dateOptionsThreed[0].data = moment(f).format();
        this.dateOptionsThreed[1].label = moment(s).format('ll');
        this.dateOptionsThreed[1].data = moment(s).format();
        this.params.date = moment(f).format('ll');
      } else if (d.getDate() <= 16 && d.getDate() === 1) {
        f.setDate(16);
        f.setMonth(d.getMonth() - 1);
        s.setDate(1);
        this.dateOptionsThreed[0].label = moment(f).format('ll');
        this.dateOptionsThreed[0].data = moment(f).format();
        this.dateOptionsThreed[1].label = moment(s).format('ll');
        this.dateOptionsThreed[1].data = moment(s).format();
        this.params.date = moment(f).format('ll');
      } else {
        f.setDate(1);
        f.setMonth(d.getMonth() + 1);
        s.setDate(16);
        this.dateOptionsThreed[0].label = moment(f).format('ll');
        this.dateOptionsThreed[0].data = moment(f).format();
        this.dateOptionsThreed[1].label = moment(s).format('ll');
        this.dateOptionsThreed[1].data = moment(s).format();
        this.params.date = moment(f).format('ll');
      }
    },
    changeType(type) {
      if (type === 'threed') {
        this.setLastHalfMonth();
      } else {
        this.params.date = moment(new Date()).format('l');
        this.params.draw = this.currentDraw();
      }
      this.type = type;
    },
    setOneMonth() {
      for (let i = 0; i < 30; i += 1) {
        const d = new Date();
        d.setHours(23, 59, 59);
        d.setDate(d.getDate() - i);
        const newObject = {};
        newObject.label = moment(d).format('l');
        newObject.data = d;
        this.dateOptions.push(newObject);
      }
    },
    async getSlipdata(data) {
      try {
        if (this.type !== 'maung' && this.type !== 'body') {
          const res = await axios.post('vouchers/datas', {
            voucher_id: data.id,
            type: data.type,
          }, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
          this.isTwod = data.type === 'twod';
          this.slipId = data.id;
          this.name = data.name;
          this.remark = data.remark;
          this.bet_date = data.type === 'twod'
            ? this.betTime(data.created_at)
            : this.betTime(data.created_at);
          this.datas = res.data.data;
          $('#voucher-history').modal('show');
        } else {
          this.datas = data.team_vouchers;
          this.bingo_amount = data.bingo_amount;
          this.slipId = data.id;
          this.is_result = data.isBet;
          this.type = data.type;
          this.bet_date = this.betTime(data.created_at);
          $('#voucher-history').modal('show');
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchHistory() {
      try {
        this.isSearch = true;
        let res;
        if (this.type === 'twod' || this.type === 'threed') {
          res = await axios.post('/vouchers/datas/all', {
            draw: this.params.draw,
            date: this.params.date,
            search: this.searchKeyword,
            isBingo: this.isBingo,
            type: this.type,
          }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
        } else {
          res = await axios.post('/fb-vouchers-history', {
            draw_date: this.params.date,
            type: this.type,
          }, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  },
              });
        }

        this.betHistory = res.data.data;
        this.rows = res.data.total;
        this.isSearch = false;
      } catch (error) {
        console.log(error);
      }
    },
    betDate(time) {
      return moment(time).format('l');
    },
    betTime(time) {
      const newTime = new Date(time);
      const targetTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const myanmarDateTime = DateTime.fromJSDate(newTime);
      const targetDateTime = myanmarDateTime.setZone(targetTimeZone);
      const adjustedDrawDate = new Date(targetDateTime.toISO()).toLocaleString('en-US', { timeZone: targetTimeZone });
      return moment(adjustedDrawDate).format('lll');
    },
    closeModal() {
      $('#voucher-history').modal('hide');
    },
    currentDraw() {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        return '12:00';
      }
      return '4:30';
    },
  },
  //   computed: {

  //   },
  watch: {
    searchKeyword() {
      if (this.searchKeyword === '') {
        this.fetchHistory();
      }
    },
    currentPage() {
      this.fetchHistory();
    },
    // datas() {
    //   $('#voucher-history').modal('show');
    // },
    type() {
      if (this.type !== 'threed') {
        this.params.date = moment(new Date()).format('l');
        this.params.draw = this.currentDraw();
      } else {
        this.setLastHalfMonth();
      }
    },
  },
  mounted() {
    if (!localStorage.getItem('token')) {
      this.$router.push('/auth/login');
    }
    this.params.date = moment(new Date()).format('l');
    this.params.draw = this.currentDraw();
    this.fetchHistory();
    this.setOneMonth();
  },
};
</script>
<style scoped>
.search {
    height: 40px;
}
.betsliptable-row {
    cursor: pointer;
}
.betslip-table {
    background: white;
}
.modal-cross {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: auto;
    color: white;
    cursor: pointer;
    margin-right: -9px;
    margin-top: -9px;
    background: rgb(228, 87, 87);
}

@media (max-width: 768px) {
    .main-wrapper {
        padding: 7px !important;
    }
    .search {
        height: 35px;
    }
    .select-containers {
        height: 32px;
    }
}
</style>
