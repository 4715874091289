var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-3 py-4"},[_c('h1',[_c('i',{staticClass:"fas fa-book mr-2 my-1"}),_vm._v(" "+_vm._s(_vm.$store.state.language === "en" ? "Rules And Regulations" : "စည်းကမ်းချက်များ")+" ")]),_c('ul',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_c('li',[_c('div',{staticClass:"d-flex justify-content-center my-4",staticStyle:{"position":"fixed","bottom":"0","left":"0","right":"0"}},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.logout}},[_vm._v("အတည်မပြုပါ")]),_c('button',{staticClass:"btn btn-primary customzie-color",on:{"click":_vm.toHome}},[_vm._v("အတည်ပြုပါသည်")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁) ")]),_vm._v("ဘော်ဒီများကိုအနည်းဆုံး (1000) ကျပ်မှ အများဆုံး (၂၀သိန်း) ကျပ်အထိကစားနိုင်ပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၂) ")]),_vm._v("ပွဲစဉ်များကို ပွဲကြီး ပွဲသေး အရောင်နဲ့ ပိုင်းခြားပြပြီး အကောက်မတူပါ။ "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("ပွဲကြီး - 5%")]),_vm._v(" "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("ပွဲသေး - 8%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၃) ")]),_vm._v("မောင်း အနည်း ဆုံး (500) ကျပ်မှ အများဆုံး (100000) ကျပ်အထိ ကစားနိုင်သည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၄) ")]),_vm._v("မောင်းများကို အနည်းဆုံး ( ၂ ) ပွဲမှ ( ၁၁ ) ပွဲအထိ ကစားနိုင်ပါသည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၅) ")]),_vm._v("ပွဲစဉ်များကို မောင်းထိုးရလွယ်ကူစေရန် ပွဲကြီး / ပွဲသေး အရောင်ဖြင့် ပိုင်းခြားပြထားပါသည်။ မောင်းအကောက်"),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("2 သင်း > 12%")]),_vm._v(" "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("3 သင်း - 8 သင်း > 15%")]),_vm._v(" "),_c('br'),_c('b',{staticClass:"ml-4"},[_vm._v("9 သင်း - 11 သင်း > 20%")]),_vm._v(" "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၆) ")]),_vm._v("သက်မှတ်ချိန် တစ်ရက်တာအတွင်း ပွဲပျက်(သို့မဟုတ်) မကန်သောပွဲစဉ်များ ရလဒ်မထွက်သောပွဲစဉ်များအား အလျော်အစားမလုပ်ပဲ ထိုးငွေအား ပြန်ပေးပါမည်။ (မှတ်ချက် - သက်မှတ်ပွဲစချိန်မှ 2 နာရီ အတွင်း မကန်သော အချိို့ပွဲစဉ်များအား ပွဲပျက်ဟု သတ်မှတ်နိုင်သည်။) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၇) ")]),_vm._v("ဂိုးရလဒ်များကို မြန်မာကြေးထွက်ရှိသော Betting Company မှဂိုးရလဒ်ဖြင့် အလျော်အစားပြုလုပ်ပါမည် ( Ibet789 / 365 ) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၈) ")]),_vm._v("အချိန်စောကန်သွားသော ပွဲစဉ်များအား MS Application တွင်မူလအချိန်အတိုင်း အချိန်မပြောင်းပဲ ဖွင့်ထားပါက ပွဲပျက်သက်မှတ်ပါမည်။ အချိန်ပြောင်းလဲထားပါက အလျော်အစားပြုလုပ်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၉) ")]),_vm._v("နည်းပညာချွတ်ယွင်း၍ အခြားသော betting company များနှင့် ပေါက်ကြေးလွန်စွာကွဲလွဲနေပါက cancal match ပြုလုပ်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၀) ")]),_vm._v("နည်းပညာချွတ်ယွင်း၍ အလျော်အစားမှားပြုလုပ်ထားသော ဘောင်ချာများအား နောက်တစ်နေ့ နေ့ခင်း 12 နာရီမတိုင်ခင် အကြောင်းကြားပေးထားသောဘောင်ချာများသာ တာဝန်ယူဖြေရှင်းပေးမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁) ")]),_vm._v("ပုံမှန်မဟုတ်သော ပွဲစဉ်များပါရှိပါက ၎င်းပွဲစဉ်အား အလျော်အစားမပြုပဲ Cancel Match ပြုလုပ်ပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၂) ")]),_vm._v("ဆော့ကစားခြင်းမရှိပဲ ငွေ အထုတ်/အသွင်း ပြုလုပ်သော အကောင့်များအား ငွေပြန်မအမ်းပဲ ပိတ်သိမ်းပါမည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-4"},[_c('span',{staticClass:"text-danger"},[_vm._v(" (၁၃) ")]),_vm._v("အငြင်းပွါးဖွယ်ရာ ကိစ္စများပေါ်ပေါက်လာပါက အများနှင့်နိူင်းယှဉ်သုံးသပ်ရသည့်အတွတ် MS ၏ ဆုံးဖြတ်ချက်သာအတည်ဖြစ်သည်။ ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h5',{staticClass:"text-center text-danger mt-4 mb-5 pb-3"},[_vm._v(" စည်းကမ်းချက်များကို သေချာစွာဖတ်ရှုပြီး သဘောတူပါကြောင်း အတည်ပြုပေးပါ။ ")])])
}]

export { render, staticRenderFns }